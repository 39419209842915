'use strict'

angular.module('cb.search-service', [])
    .factory('SearchService', ["ApiService", function (ApiService) {

        var searchService = {
            currentCustomerId: "",
            searchCustomer: function (searchData) {
                try {
                    var data = {
                        RefNumber: searchData.Validated.RefNumber,
                        Surname: searchData.Validated.Surname,
                        HomePhone: searchData.Validated.Phone,
                        StatusId: searchData.Validated.SelectedStatus != null ? searchData.Validated.SelectedStatus.Id : 0,
                        LeadProviderId: searchData.Validated.SelectedLeadProvider != null ? searchData.Validated.SelectedLeadProvider.Id : 0,
                        Postcode: searchData.Validated.Postcode,
                        CompanyName: searchData.Validated.Company,
                        LeadStatusGroupId: searchData.Validated.SelectedStatusGroup != null ? searchData.Validated.SelectedStatusGroup.Id : 0,
                        Match: searchData.NotValidated.Match == 'any' ? true : false,
                        IncludeDead: true, // searchData.NotValidated.CheckboxModel,
						ActionId: searchData.Validated.SelectedAction != null ? searchData.Validated.SelectedAction.Id : 0,
						Email: searchData.Validated.Email,
						Pagination: searchData.Pagination,
						SearchMode: searchData.SearchMode
                    }
                    var url = '/webapi/api/v1/search/CustomerSearch';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			BulkSearch: function(input) {
				var url = '/webapi/api/v1/search/bulksearch';
				return ApiService.promisePost(url, input);
			},
			globalSearch: function (input) {
				var url = '/webapi/api/v1/search/globalsearch';
				return ApiService.promisePost(url, input);
			},
            searchSaleOrder: function (searchData) {
                try {
                    var data = {
                        RefNumber: searchData.Validated.RefNumber,
                        Surname: searchData.Validated.Surname,
                        Postcode: searchData.Validated.Postcode, 
                        CompanyName: searchData.Validated.Company, 
                        ActionId: searchData.Validated.SelectedAction != null ? searchData.Validated.SelectedAction.Id : 0,
                        ClosedSearch: searchData.ClosedSearch,
                        UserType: searchData.UserType,
						UserId: searchData.UserId,
						Email: searchData.Validated.Email
                    }
                    var url = '/webapi/api/v1/search/SaleOrderSearch';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            viewOrderCustomer: function (id) {
                try {
                    var url = '/webapi/api/v1/leadProfile/viewLead/' + id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			postcodeSearch: function (data) {
				try {
					var url = '/webapi/api/v1/search/postcodeSearch';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
            viewOrder: function (id) {
                try {
                    var url = '/webapi/api/v1/SaleOrder/viewOrder/' + id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error', e);
                }
			},
			quickSearch: function (data) {
				try {
					var url = '/webapi/api/v1/search/postcodeQuickSearch';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			quickSearch2: function (data) {
				try {
					var url = '/webapi/api/v1/search/postcodeQuickSearch2';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			}
        };

        return searchService;
    }]);
